import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Share'

// Context
import { BlogContext } from 'templates/post'

// Components
import Form from 'components/related/Form'

const Block = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`

const BlockContent = styled.div`
  color: ${({ theme }) => theme.color.text.purple};

  & h2 {
    font-size: ${({ theme }) => theme.font.size.l};
  }

  & h3 {
    font-size: ${({ theme }) => theme.font.size.xm};
  }
`

const CtaImage = styled(Image)`
  width: 150px;
`

const CtaDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${({ theme }) => theme.font.size.l};
  }
`

interface BlogFormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogForm
  location: any
}

const BlogForm: React.FC<BlogFormProps> = ({ fields, location }) => {
  const blogContext = React.useContext(BlogContext)

  return (
    <>
      <section className="color-background-background pb-5" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 my-5">
              <Block className="p-4 p-lg-5">
                <div className="mx-lg-5 py-4">
                  <div className="row">
                    <div className="col-sm-7 d-flex align-items-center">
                      <div>
                        <BlockContent>
                          <ParseContent content={fields?.description} />
                          {fields?.showtitle && (
                            <h2>
                              <strong>{blogContext.title}</strong>
                            </h2>
                          )}
                        </BlockContent>

                        {fields?.formid && (
                          <Form
                            className="w-100 mt-4"
                            id={fields?.formid}
                            defaultFields={{ input_6: blogContext.title }}
                            privacyUrl="/privacyverklaring"
                            privacyUrlPlacement="privacybeleid"
                            scrollToConfirm={false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-sm-5 justify-content-center d-flex mt-4 mt-sm-0">
                      <div className="d-flex align-items-center w-100 flex-column justify-content-between">
                        <div className="text-center">
                          <CtaImage
                            className="mx-auto"
                            image={fields.cta?.image}
                          />
                          <CtaDescription
                            className="mt-3"
                            content={fields.cta?.description}
                          />
                        </div>

                        <div className="mt-4">
                          <div className="color-contrast font-size-s text-sm-left text-center">
                            share
                          </div>
                          <Share
                            className="d-flex mt-1"
                            location={location.href}
                            title={blogContext.title}
                            type="horizontal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Block>
            </div>
          </div>
        </div>
      </section>
      <div className="py-4" />
    </>
  )
}

export default BlogForm
